var config = {
  // Set the Demo Version
  demo: false,

  //SEO Configurations
  appName: "Edu Vai Exam",
  metaDescription: "Edu Vai Exam is a Web Quiz Application",
  metaKeyWords: "Edu Vai Quiz,Exam,Questions,Answers,Online Exam",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://eadmin.eduvai.com/api/",


  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Exam Configurations
  questionTimerSeconds: 15,
  levelWinCheckPoint: 30, // Above 30% is required to Clear the Exam Level
  maxWinningCoins: 4, // This will give user the coins if user will clear the level
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  addCorrectAnswerScore: 2, // This will increase the user score after each correct answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  DefaultCountrySelectedInMobile: "bd", //Default Country Selected in Mobile Login Screen

  //Firebase Configurations
  apiKey: "AIzaSyCK1ZOCZI6XApNRsW2LiPxxg2faR3F_ltw",
  authDomain: "eduvai.firebaseapp.com",
  projectId: "eduvai",
  storageBucket: "gs://eduvai.appspot.com/",
  messagingSenderId: "60462065716",
  appId: "1:60462065716:web:a1c76a74d85b512e70c261",
  measurementId: "G-NX583M2MT2",

  //footer area
  companytext:
    "Edu Vai Exam is to provide low cost employment assistance.",
  addresstext:
    "Address: South Banasree, Dhaka, Bangladesh.",
  phonenumber: "+88 09611833228",
  email: "info@sothik.com",
  facebooklink: "https://www.facebook.com/EduVaiApp",
  instagramlink: "https://www.instagram.com/EduVaiApp",
  linkedinlink: "https://www.linkedin.com/company/EduVaiApp",
  weblink: "https://eduvai.com/",
  companyname: "Sothik IT",
};

export default config;
